.adminSitesItem {
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}
.adminSitesItem:hover {
    border: 1px solid #231E33;
}
.asiImg {
    width: 200px;
    height: 200px;
    object-fit: cover;
}
.asiDesc {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 2;
}
.asiTitle {
    font-size: 20px;
    color: #6726C3;
}
.asiLocation {
    font-size: 15px;
    font-weight: bold;
}
.asiAddress {
    font-size: 13px;
}
.asiContact {
    font-size: 13px;
}
.asiRoomCount {
    font-size: 13px;
}
.asiDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.asiUpdateBtn {
    background-color: #5009B5;
    color: white;
    font-weight: bold;
    padding: 10px 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
@media only screen and (max-width: 600px) {
    .asiImg {
        width: 100px;
        height: 170px;
        object-fit: cover;
    }
    .asiTitle {
        font-size: 14px;
    }
    .asiDesc {
        gap: 3px;
    }
    .asiLocation, .asiAddress, .asiContact, .asiRoomCount {
        font-size: 12px;
    }
    .asiUpdateBtn {
        font-size: 12px;
    }
}
.reportsContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.reportsHeader {
    width: 100%;
    height: 64px;
    background-color: rgb(141, 35, 212);
    color: white;
}
.reportsHeader>h3 {
    padding-left: 100px;
    line-height: 64px;
}
.reportsContent {
    display: flex;
    justify-content: center;
    padding: 20px;
}
.reportPrintBtn {
    background-color: #5009B5;
    color: white;
    font-weight: bold;
    padding: 10px 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-transform: capitalize;
}
@media only screen and (max-width: 600px) {
    .reportsContent {
        padding-top: 10px;
        overflow-x: hidden;
    }
}
.searchBarSelect {
    border: none;
    outline: none;
    color: #231E33;
    line-height: 1.1rem;
    font-size: 1rem;
    cursor: pointer;
}
.searchBarOptions {
    color: #231E33;
    width: max-content;
    line-height: 1.1rem;
    font-size: 1rem;
}
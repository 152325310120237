.modalMessage {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.modalMessageContainer {
    width: max-content;
    height: max-content;
    background-color: white;
    padding: 20px;
    position: relative;
    border-radius: 10px;
}
.actionCloseModal {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: black;
}
.modalMessageTitle {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: 10px;
    text-transform: capitalize;
}
.modalMessageForm {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
}
.modalMessageFormItem {
    font-size: 14px;
    display: flex;
    flex-direction: column;
}
.modalMessageFormItem>span {
    font-size: 13px;
}
.modalMessageInput {
    width: 250px;
    padding: 10px;
}
.modalMessageBtn {
    background-color: #5009B5;
    color: white;
    font-weight: bold;
    padding: 10px 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-transform: capitalize;
}
.modalMessageBtn:disabled {
    background-color: #888BF1;
    cursor: not-allowed;
}
@media only screen and (max-width: 600px) {
    .modalMessageContainer {
        width: 80%;
    }
}
.searchContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.searchHeader {
    width: 100%;
    height: 64px;
    background-color: rgb(141, 35, 212);
    color: white;
}
.searchHeader>h3 {
    padding-left: 100px;
    line-height: 64px;
}
.searchContent {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.searchBarContainer {
    width: 100%;
}
.searchTypeWrapper {
    display: flex;
    gap: 15px;
    padding-left: 20px;
    padding-top: 5px;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    justify-content: center;
    margin-bottom: 10px;
}
.searchTypeWrapper::-webkit-scrollbar, .searchTypeWrapper::-webkit-scrollbar  {
    display: none;
}
.searchBarWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    justify-content: center;
}
.searchBarParams {
    display: flex;
    padding-top: 5px;
    min-height: 60px;
    width: 58%;
    color: #231E33;
    overflow-y: hidden;
    gap: 5px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
}

.searchBarItem {
    padding: 5px 5px ;
    min-height: 30px;
    width: 90%;
    background-color: white;
    color: #231E33;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: space-around;
    border: 3px solid #6726C3;
    border-radius: 5px;
    gap: 10px;
}
.searchBarIcon {
    color: #231E33;
}
.searchBarItemWrapper {
    display: flex;
    width: 75%;
    align-items: center;
    justify-content: space-between;
}
.searchBarSelect {
    border: none;
    outline: none;
    color: #231E33;
    line-height: 1.1rem;
    font-size: 1rem;
    cursor: pointer;
}
.searchBarText {
    color: #231E33;
    cursor: pointer;
}
.searchBarText.pax {
    cursor: pointer;
}
.searchBarDateRange {
    position: relative;
    top: 0px;
    left: 0px;
}
.searchBarOptions {
    color: #231E33;
    width: max-content;
    line-height: 1.1rem;
    font-size: 1rem;
}
.options {
    top: 50px;
    color: gray;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 10px -5px rgba(0,0,0, 0.4);
    box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.4);
    z-index: 99;
}
.optionItem {
    width: 70px;
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.optionCounter {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    color: #231E33;
}

.optionCounterButton {
    width: 30px;
    height: 30px;
    border: 1px solid #6726C3;
    color: #6726C3;
    cursor: pointer;
    background-color: white;
}

.optionCounterButton:disabled {
    cursor: not-allowed;
}

.searchBarButton {
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.searchBtn {
    height: 60px;
    width: 200px;
    background-color: rgb(141, 35, 212);
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


@media only screen and (max-width: 600px) {
    .searchHeader>h3 {
        padding-left: 30px;
    }
    .searchTypeWrapper {
        padding-left: 20px;
        padding-right: 20px;
        overflow-y: hidden;
    }
    .searchBarDateRange {
        position: relative;
        top: 0px;
        left: 0px;
    }
    .searchBarParams {
        align-items: center;
        flex-direction: column;
        gap: 5px;
    }
    .searchBarItem {
        width: 90%;
        justify-content: space-around;
    }
    .searchBarItemWrapper {
        display: flex;
        width: 75%;
        align-items: center;
        justify-content: space-between;
    }
    .searchBarOptions {
        line-height: 14px;
        font-size: 12px;
    }
}
.modalAvailability {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.modalAvailabilityContainer {
    width: 400px;
    height: 400px;
    background-color: white;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}
.closeModal {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: black;
}
.modalAvailabilityTitle {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: 10px;
}
.modalAvailabilityContent {
    width: 350px;
    height: 400px;
    gap: 10px;
    padding: 20px;
    overflow-x: hidden;
}
.timeBlockWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media only screen and (max-width: 600px) {
    .modalAvailabilityContainer {
        width: 85%;
    }
    .modalAvailabilityContent {
        width: 100%;
        padding: 0;
    }
}

.titleImageContainer {
    width: 100%;
    height: 192px;
    text-align: center;
    background-position: 50% center;
    background-size: cover;
    background-image: url('./../../resources/carelon/workdayBanner.png');
    font-size: 80px;
    font-weight: 900;
    color: rgb(255, 255, 255);
}
@media only screen and (max-width: 600px) {
    .titleImageContainer {
        height: 128px;
        font-size: 32px;
    }
}
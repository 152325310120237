.updateRoomModal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.updateRoomModalContainer {
    width: 400px;
    height: max-content;
    background-color: white;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}
.closeModal {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: black;
}
.updateRoomModalTitle {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: 10px;
}
.updateRoomModalForm {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;
}
.updateRoomModalFormColumn {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    gap: 10px;
}
.updateRoomModalFormItem {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.updateRoomModalFormItem>label {
    text-align: end;
    vertical-align: middle;
    font-weight: 600;
    font-size: 14px;
    flex: 1;
    padding-right: 10px;
}
.updateRoomInput {
    width: 210px;
}
.updateRoomModalFormItemSelect {
    width: 220px;
}
.updateRoomModalBtn {
    background-color: #5009B5;
    color: white;
    font-weight: bold;
    padding: 10px 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
.updateRoomModalBtn:disabled {
    background-color: #888BF1;
    cursor: not-allowed;
}
@media only screen and (max-width: 600px) {
    .updateRoomModalContainer {
        width: 85%;
    }
    .updateRoomModalForm {
        width: 100%;
        padding: 0;
    }
}
.assocHomeContainer {
    width: 100%;
    height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;
    background-color: rgb(240, 241, 242);
}
.assocHomeBody {
    display: flex;
    width: 80%;
    margin: 32px auto 0px;
    padding: 0px 32px 40px;
    transition: all 100ms ease-in-out 0s;
    -webkit-box-pack: justify;
    justify-content: space-around;
    flex-direction: column;
}
.assocHomeTopSection, .assocHomeLowerSection {
    width: 100%;
    align-self: center;
    display: flex;
    justify-content: space-between;
}
.assocHomeTopSection {
    flex-direction: column;
    padding-bottom: 15px;
}
.assocHomeTopSectionLowerRow {
    display: flex;
    flex-direction: row;
}
.assocHomeLowerSection {
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.assocHomeGreetings {
    height: 48px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1.25rem;
    letter-spacing: 0.015rem;
    color: rgb(73, 73, 73);
    flex: 1;
}
.assocHomeTimeNow {
    flex: 1;
    font-size: 1.1rem;
    line-height: 1.4;
    flex-shrink: 1;
    color: rgb(94, 106, 117);
    height: 48px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: flex-end;
}
.assocTiles {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
}
.assocUpcoming {
    flex: 2
}
.assocTasks {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    min-height: 250px;
    gap: 20px;
}
.assocTiles>div {
    text-align: center;
}
.assocTasks>h3 {
    padding-bottom: 15px;
}
.assocTasks>button {
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
}
.assocLinks {
    text-align: center;
}
.assocHomeBtn {
    padding: 10px;
    background-color: #5009B5;
    color: white;
    border: none;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
}
.assocHomeBtn:hover {
    background-color: #6f25d7;
}
@media only screen and (max-width: 600px) {
    .assocHomeBody {
        width: unset;
        margin: 0;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 0;
        justify-content: flex-start;
    }
    .assocHomeLowerSection {
        justify-content: space-evenly;
    }
    .assocHomeGreetings, .assocHomeTimeNow {
        font-size: 1rem;
    }
    .assocHomeTimeNow {
        text-align: end;
    } 
    .assocTasks {
        display: flex;
        flex-direction: column;
        min-height: 170px;
        height: 100%;
        gap: 10px;
    }
    .assocTasks>a {
        display: flex;
        justify-content: center;
        text-decoration: none;
    }
    .assocTasks>h3 {
        text-align: center;
        width: 40%;
        padding-top: 15px;
    }
    .homeBtn {
        align-self: center;
        width: 60%;
    }
    .assocHomeTopSection {
        flex-direction: row;
    }
    .assocHomeLowerSection {
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
    }
    .assocHomeTopSectionLowerRow {
        width: 100%;
    }
}
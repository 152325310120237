.roomsContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.roomsHeader {
    width: 100%;
    height: 64px;
    background-color: rgb(141, 35, 212);
    color: white;
}
.roomsHeader>h3 {
    padding-left: 100px;
    line-height: 64px;
}
.roomListFilterBarWrapper {
    padding: 10px;
}
@media only screen and (max-width: 600px) {
    .roomListFilterBarWrapper {
        padding: 0 10px;
        padding-bottom: 10px;
    }
}
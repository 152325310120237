.rlrContainer {
    display: flex;
    justify-content: center;
}
.rlrWrapper {
    width: 100%;
    max-width: 1024px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
}
.listTitle {
    font-size: 20px;
}
@media only screen and (max-width: 600px) {
    .listTitle {
        padding-left: 20px;
        font-size: 16px;
    }
    .rlrList {
        padding-left: 10px;
        padding-right: 10px;
    }
}

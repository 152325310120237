.adminHomeContainer {
    width: 100%;
    height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;
    background-color: rgb(240, 241, 242);
}
.adminHomeBody {
    display: flex;
    width: 80%;
    height: 100vh;
    margin: 32px auto 0px;
    padding: 0px 32px 40px;
    transition: all 100ms ease-in-out 0s;
    -webkit-box-pack: justify;
    justify-content: space-around;
    flex-direction: column;
}
.homeTopSection, .homeLowerSection {
    width: 100%;
    align-self: center;
    display: flex;
    justify-content: space-between;
}
.homeTopSection {
    flex-direction: column;
    padding-bottom: 15px;
}
.homeTopSectionLowerRow {
    display: flex;
    flex-direction: row;
}
.homeLowerSection {
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}
.homeGreetings {
    height: 48px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1.25rem;
    letter-spacing: 0.015rem;
    color: rgb(73, 73, 73);
    flex: 1;
}
.homeTimeNow {
    flex: 1;
    font-size: 1.1rem;
    line-height: 1.4;
    flex-shrink: 1;
    color: rgb(94, 106, 117);
    height: 48px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: flex-end;
}
.tiles {
    background-color: white;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
}
.awaiting, .upcoming {
    width: 100%;
    height: 100%;
}
.tasks {
    width: 30%;
    min-width: 200px;
    min-height: 250px;
}
.tiles>div {
    text-align: center;
    padding-top: 15px;
}
.tasks>h3 {
    padding-bottom: 15px;
}
.tasks>button, .homeBtn {
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
}
.tasks>button:hover, .homeBtn:hover {
    background-color: rgb(235, 230, 230);
}
@media only screen and (max-width: 600px) {
    .adminHomeBody {
        width: unset;
        margin: 0;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 0;
        justify-content: flex-start;
    }
    .homeGreetings, .homeTimeNow {
        font-size: 0.7em;
    }
    .tiles {
        width: 100%;
        padding: 0;
    }
    .tasks {
        display: flex;
        flex-direction: column;
        min-height: 170px;
    }
    .tasks>a {
        display: flex;
        justify-content: center;
        text-decoration: none;
    }
    .tasks>h3 {
        text-align: center;
        width: 40%;
        padding-top: 15px;
    }
    .homeBtn {
        align-self: center;
        width: 60%;
    }
    .homeLowerSection {
        flex-direction: column;
    }
    .lowerSection {
        justify-content: center;
    }
}
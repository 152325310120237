.forYourActions {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    padding-bottom: 20px;
}
.FYASitesWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.forYourActions>h3 {
    align-self: flex-start;
}
.forYourActionLink {
    padding-top: 20px;
    text-decoration: none;
    color: rgb(73, 73, 73);
}
.forYourActionLink:hover {
    text-decoration: none;
}

@media only screen and (max-width: 600px) {
    
}
.upcomingReservationsItem {
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    margin-bottom: 20px;
}
.upcomingReservationsItemType {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
}
.upcomingReservationsItemTypeImg {
    height: 15px;
    padding-left: 10px;
}
.upcomingReservationsItem>h4, .upcomingReservationsItem>span {
    padding-left: 10px;
}
.upcomingReservationsItem>span {
    font-size: 12px;
}
.upcomingReservationsItemBox {
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}
.upcomingReservationsItemBox:hover {
    border: 1px solid black;
}
.upcomingReservationsItemBoxImg {
    width: 150px;
    height: 150px;
    object-fit: cover;
    flex: 1;
}
.upcomingReservationsItemBoxDesc {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex: 4;
    justify-content: space-evenly;
}
.upcomingReservationsItemRoomDetails {
    display: flex;
    flex-direction: column;
}
.upcomingReservationsItemBoxName {
    font-size: 20px;
    color: #6726C3;
}
.upcomingReservationsItemSubtitle {
    font-size: 15px;
    font-weight: bold;
}
.upcomingReservationsItemDates {
    align-self: center;
    display: flex;
    flex-direction: column;
}
.upcomingReservationsItemDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.upcomingReservationsItemDetailsBtn {
    background-color: #09b596;
    color: white;
    font-weight: bold;
    padding: 10px 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
.upcomingReservationsItemDetailsBtn:disabled {
    background-color: #b2aeb8;
    cursor: not-allowed;
}
@media only screen and (max-width: 600px) {
    .upcomingReservationsItem {
        padding-left: 5px;
        padding-right: 5px;
    }
    .upcomingReservationsItemBox {
        gap: 10px;
    }
    .upcomingReservationsItemBoxImg {
        width: 100px;
        height: 170px;
        object-fit: cover;
    }
    .upcomingReservationsItemDates {
        font-size: 14px;
        width: 130px;
    }
    .upcomingReservationsItemBoxName {
        font-size: 16px;
    }
    .upcomingReservationsItemSubtitle {
        font-size: 12px;
        font-weight: bold;
    }
}
.reservationsContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.reservationsHeader {
    width: 100%;
    height: 64px;
    background-color: rgb(141, 35, 212);
    color: white;
}
.reservationsHeader>h3 {
    padding-left: 100px;
    line-height: 64px;
}
.reservationsContent {
    display: flex;
    justify-content: center;
    padding: 10px;
}
.reservationsContentWrapper {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
}
.reservationsContentList {
    font-size: 16px;
}
@media only screen and (max-width: 600px) {
    .reservationsContent {
        padding-left: 0px;
        padding-right: 0px;
    }
    .reservationsHeader>h3 {
        padding-left: 30px;
    }
}
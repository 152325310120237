.pbsuContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.pbWrapper {
    width: 150px;
    height: 150px;
}
.pbsuTitle {
    font-weight: 500;
    color: gray;
    text-align: center;
}
.pbsuTotalHours {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
}
@media only screen and (max-width: 600px) {
    .pbsuTotalHours {
        font-weight: 700;
    }
}
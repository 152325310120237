.sitesContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.sitesHeader {
    width: 100%;
    height: 64px;
    background-color: rgb(141, 35, 212);
    color: white;
}
.sitesHeader>h3 {
    padding-left: 100px;
    line-height: 64px;
}
.sitesWrapper {
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}
.sitesTitle {
    font-size: 20px;
    margin-bottom: 10px;
    color: #231E33;
}
@media only screen and (max-width: 600px) {
    .sitesHeader>h3 {
        padding-left: 30px;
    }
}
.reservationsItem {
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    margin-bottom: 20px;
}
.reservationsItemType {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
}
.reservationsItemTypeImg {
    height: 15px;
    padding-left: 10px;
}
.reservationsItem>h4, .reservationsItem>span {
    padding-left: 10px;
}
.reservationsItem>span {
    font-size: 12px;
}
.reservationsItemBox {
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}
.reservationsItemBox:hover {
    border: 1px solid black;
}
.reservationsItemBoxImg {
    width: 150px;
    height: 150px;
    object-fit: cover;
    flex: 1;
}
.reservationsItemBoxDesc {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 4;
    justify-content: space-evenly;
}
.reservationsItemBoxName {
    font-size: 20px;
    color: #6726C3;
}
.reservationsItemSubtitle {
    font-size: 15px;
    font-weight: bold;
}
.reservationsItemDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.reservationsItemDetailsBtn {
    background-color: #5009B5;
    color: white;
    font-weight: bold;
    padding: 10px 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
@media only screen and (max-width: 600px) {
    .reservationsItem {
        padding-left: 5px;
        padding-right: 5px;
    }
    .reservationsItemBox {
        gap: 10px;
    }
    .reservationsItemBoxImg {
        width: 100px;
        height: 170px;
        object-fit: cover;
    }
    .reservationsItemDates {
        font-size: 14px;
        width: 130px;
    }
    .reservationsItemBoxName {
        font-size: 16px;
    }
    .reservationsItemSubtitle {
        font-size: 12px;
        font-weight: bold;
    }
}
.forYourActionLink:hover {
    cursor: pointer;
}
.FYAIcons {
    font-size: 3rem;
}
.FYASite {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    border-radius: 20px;
    border: 1px solid black;
    padding: 20px;
}
.FYAInfo>span:hover {
    text-decoration: underline;
}
@media only screen and (max-width: 600px) {
    .FYASitesWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .FYASite {
        margin-bottom: 50px;
    }
}
.roomListItem {
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}
.roomListItem:hover {
    border: 1px solid black;
}
.rliImg {
    width: 200px;
    height: 200px;
    object-fit: cover;
}
.rliDesc {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 2;
}
.rliName {
    font-size: 20px;
    color: #6726C3;
}
.rliSubtitle {
    font-size: 15px;
    font-weight: bold;
}
.rliFeatures, .rliLabels {
    font-size: 13px;
}
.rliDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.rliUpdateBtn {
    background-color: #5009B5;
    color: white;
    font-weight: bold;
    padding: 10px 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
@media only screen and (max-width: 600px) {
    .roomListItem {
        gap: 10px;
    }
    .rliImg {
        width: 100px;
        height: 170px;
        object-fit: cover;
    }
    .rliName {
        font-size: 13px;
    }
    .rliDesc {
        gap: 3px;
    }
    .rliSubtitle, .rliLabels, .rliAddress, .rliContact, .rliRoomCount {
        font-size: 11px;
    }
    .rliUpdateBtn {
        font-size: 12px;
        padding: 10px;
    }
}
.searchItem {
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}
.searchItem:hover {
    border: 1px solid #231E33;
}
.searchItemImg {
    width: 200px;
    height: 200px;
    object-fit: cover;
}
.searchItemDesc {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 2;
}
.searchItemName {
    font-size: 20px;
    color: #6726C3;
}
.searchItemSubtitle {
    font-size: 15px;
    font-weight: bold;
}
.searchItemFeatures {
    font-size: 12px;

}
.searchItemDesc>label {
    font-size: 13px;
}

.searchItemDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.searchItemReserveBtn {
    background-color: #5009B5;
    color: white;
    font-weight: bold;
    padding: 10px 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
.searchItemReserveBtn:disabled {
    background-color: #888BF1;
    cursor: not-allowed;
}
@media only screen and (max-width: 600px) {
    .searchItemImg {
        width: 100px;
        height: 170px;
        object-fit: cover;
    }
    .searchItemName {
        font-size: 16px;
    }
    .searchItemSubtitle {
        font-size: 12px;
    }
    .searchItemDesc>label {
        font-size: 12px;
    }
}

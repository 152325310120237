.modalNotification {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.modalNotificationContainer {
    width: 400px;
    height: max-content;
    background-color: white;
    padding: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}
.closeModal {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: black;
}
.modalNotificationTitle {
    display: flex;
    align-content: center;
    justify-content: center;
}
.modalNotificationForm {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;
}
.modalNotificationFormColumn {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    gap: 10px;
}
.modalNotificationFormItem {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    text-align: justify;
    padding-bottom: 8px;
}
.modalNotificationFormItem>span {
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
    flex: 1;
    padding-right: 10px;
}
.updateModalFormItem>span {
    font-size: 13px;
    flex: 3;
}
.updateModalFormItem>textarea {
    width: 210px;
}
.updateSiteInput {
    width: 210px;
}
.updateSiteInput.address {
    height: 50px;
}
.modalNotificationBtn {
    background-color: #5009B5;
    color: white;
    font-weight: bold;
    padding: 10px 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
.modalNotificationBtn:disabled {
    background-color: #888BF1;
    cursor: not-allowed;
}